@use 'sass:math';

@import '~/src/styles/_variables.scss';
@import '~/src/styles/_mixins.scss';

.homepage {
  width: 100vw;
  box-sizing: border-box;
  h1 {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 3.5px;
    text-transform: uppercase;
  }
  .title {
    width: fit-content;
    width: -moz-fit-content; /* Firefox */
    width: -webkit-fit-content; /* Chrome */
    font-weight: 900;
    line-height: 30px;
    letter-spacing: 3.5px;
    color: #ffffff;
    border-bottom: 3px solid #e9ff70;
    text-transform: uppercase;
  }
  button {
    padding: 10px 30px;
    border-radius: 3px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    &:hover {
      -webkit-transform: translateY(-1px);
      transform: translateY(-1px);
    }
  }
  .yellowButton {
    background: #e9ff70;
    color: $rivall-navy;
    border: none;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
  }
  .playNowButton {
    width: 100%;
    height: 100%;
  }
  .blueButton {
    background: $rivall-navy;
  }

  .hero-action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .card {
    padding: 25px 20px;
    margin: 0 5px;
    border-radius: 4px;
    height: 100%;
    margin-bottom: 20px;
    position: relative;
    background: #ffffff;
    border-radius: 16px;
    text-align: left;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
  }
  .tierCard {
    margin: 5px;
    border-radius: 50px 50px 16px 16px;
    height: 100%;
    position: relative;
    background: #ffffff;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
    h4 {
      font-weight: 500;
      font-size: 28px;
      line-height: 26px;
      color: white;
      text-transform: uppercase;
    }
    ul {
      padding-left: 8px;
      list-style: none;
      display: flex;
      flex-direction: column;
      li {
        align-items: center;
        display: flex;

        img {
          height: 10px;
          margin-right: 8px;
        }
      }
    }
    .tierBody {
      padding: 30px 20px;
      text-align: left;
    }
    .tierHeader {
      background-color: lighten($rivall-navy, 5%);
      padding: 20px 16px;
      text-align: center;
      border-radius: 16px 16px 0 0;
      h4 {
        font-weight: 800;
      }
    }
  }
  .holidayTierCard {
    margin: 0 2rem 0 0;
    .tierHeader {
      h4 {
        font-size: 22px;
        color: $rivall-navy;
      }
    }

    .tierBody {
      padding: 30px 40px;
      text-align: left;
    }
  }
  .titleBox {
    text-transform: uppercase;
    background: $rivall-navy;
    background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png');
    background-image: -webkit-image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.avif') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.webp') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png') 1x
    );
    background-image: image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.avif') type('image/avif'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.webp') type('image/webp'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png') type('image/png')
    );

    border-radius: 3px;
    padding: 15px 0 25px;
    position: relative;
    top: 10px;
    h2 {
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 3.5px;
      color: #ffffff;
      border-bottom: 2px solid #e9ff70;
      padding: 15px;
      width: fit-content;
      width: -moz-fit-content; /* Firefox */
      width: -webkit-fit-content; /* Chrome */
      margin: 0 auto;
    }
  }
  .titleHeader {
    background: $rivall-navy;
    width: 100vw;
    padding: 30px;
    margin-bottom: 30px;
    text-align: center;
    h2 {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      color: #e9ff70;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.6px;
      color: #ffffff;
    }
  }
  .fancyText {
    color: $rivall-navy;
    text-shadow: 0px 2px 0px #ffffff;
    margin-bottom: 10px;
    background: linear-gradient(transparent 60%, #e9ff70 20%) no-repeat;
    border-radius: 6px;
    width: fit-content;
    width: -moz-fit-content; /* Firefox */
    width: -webkit-fit-content; /* Chrome */
  }
  .ctaButton {
    background: transparent;
    border: 2.6px solid #e9ff70;
    a {
      color: #e9ff70;
    }
  }
  .feature {
    text-align: left;
    margin: 40px 0;
    h4 {
      font-weight: bold;
      font-size: 18px;
      letter-spacing: 0.15px;
      color: #17406e;
    }
    .icon {
      width: 66px;
      height: 66px;
      line-height: 44px;
      font-size: 24px;
      padding: 10px;
      border-radius: 50%;
      text-align: center;
      color: black;
      font-weight: 500;
      float: left;
      border: 2px solid $rivall-navy !important;
    }
  }
  img {
    object-fit: contain;
  }
  .placeholder {
    width: 100%;
    height: 100%;
    background: gray;
  }
  .apps {
    height: 100px;
    width: 100%;
    &:hover {
      cursor: pointer;
    }
  }
  .faqs {
    .card {
      height: auto;
      margin-bottom: 20px;
      position: relative;
      background: #ffffff;
      border-radius: 16px;
      text-align: left;
      padding: 20px 25px;
      box-shadow:
        0px 4px 8px rgba(38, 50, 56, 0.08),
        0px 2px 4px rgba(38, 50, 56, 0.16),
        0px -4px 4px rgba(0, 0, 0, 0.03);
      h3 {
        font-weight: bold;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.2px;
        color: #2b2b2b;
        margin-bottom: 5px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
        text-align: justify;
        letter-spacing: 0.2px;
      }
      .arrow {
        position: absolute;
        top: 15px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.15px;
    color: #2b2b2b;
  }

  @media screen and (max-width: 485px) {
    padding-right: 0;
  }
}

.queryApp {
  filter: brightness(50%);
  background: white;
  cursor: not-allowed;
  pointer-events: none;
  height: calc(100vh - 31px);
  overflow: hidden !important;
}

.useApp {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  .headerChoice {
    border-bottom: 1px solid;
    border-color: #edeff1;
    color: #1a1a1b;
    font-size: 18px;
    font-weight: 700;
    height: 52px;
    line-height: 26px;
    margin: 0 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }
  .choices {
    padding: 16px 20px;
  }
  .appChoice {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    line-height: 32px;
    img {
      height: 40px;
      width: auto;
      border-radius: 10px;
      margin-right: 10px;
      object-fit: contain;
    }
    a {
      color: white;
    }
    span {
      position: relative;
      bottom: 14px;
    }
  }
}

.sponsorBar {
  background: #e0e0e0;
  height: 120px;
  width: 100vw;
  img {
    height: 80px;
    width: auto;
    margin: 20px 0;
  }
}

.squeeze + .heroContainer {
  padding-top: 58px;
}

.heroContainer {
  @extend .homepage;
  overflow: visible;
  box-sizing: border-box;
  .carousel {
    overflow-y: hidden;
    transition: max-height 0.7s linear;
    max-height: 550px;
  }
  ul {
    margin-bottom: 115px;
  }
  .hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 550px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    &.corporate-hero {
      background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.jpg');
      background-image: -webkit-image-set(
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.avif') 1x,
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.webp') 1x,
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.jpg') 1x
      );
      background-image: image-set(
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.avif')
          type('image/avif'),
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.webp')
          type('image/webp'),
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/corporate-hero.jpg')
          type('image/jpeg')
      );
      height: 23rem;
    }
    &.trivia-hero {
      background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.jpg');
      background-image: -webkit-image-set(
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.avif') 1x,
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.webp') 1x,
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.jpg') 1x
      );
      background-image: image-set(
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.avif')
          type('image/avif'),
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.webp')
          type('image/webp'),
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.jpg')
          type('image/png')
      );
    }
    &.gameshow-hero {
      background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.jpg');
      background-image: -webkit-image-set(
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.avif') 1x,
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.webp') 1x,
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.jpg') 1x
      );
      background-image: image-set(
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.avif')
          type('image/avif'),
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.webp')
          type('image/webp'),
        url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/hero-img-2.jpg')
          type('image/png')
      );
    }

    @include media-breakpoint-down(md) {
      height: 475px;
    }
  }
  .jumbotron {
    text-align: center;
    background: #03111bc7;
    border-radius: 3px;
    padding: 40px;
    min-height: 250px;
    max-width: 668px;
    min-width: 668px;
    position: absolute;
    display: flex;
    flex-direction: column;

    .title {
      max-width: 100%;
      width: 100%;
      text-align: center;
      word-wrap: break-word;
    }
    .subtitle {
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      margin: 15px 0;
      text-align: center;
      letter-spacing: 0.25px;
      color: #ffffff;
      white-space: pre-line;
      p {
        color: #ffffff;
      }
    }

    @include media-breakpoint-down(sm) {
      max-width: 90vw;
      min-width: 90vw;
    }
  }

  .heroButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background: $rivall-navy;
    max-width: calc(100% - 15px);
    border-radius: 3px;
    padding: 30px;
    position: relative;
    min-height: 120px;
    margin: -90px 0 50px 0;
    z-index: 1;

    @include media-breakpoint-down(md) {
      max-width: 100%;
      top: 0;
      margin: 50px 0;
      flex-direction: column;
    }
  }
}

.waysToPlay {
  margin-bottom: 50px;
  margin-top: 50px;
  .card {
    color: white;
    background: $rivall-navy;
    background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png');
    background-image: -webkit-image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.avif') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.webp') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png') 1x
    );
    background-image: image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.avif') type('image/avif'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.webp') type('image/webp'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png') type('image/png')
    );
    margin: 0;
    padding-bottom: 0;
    padding-top: 20px;
    .evenTitle {
      position: relative;
      min-height: 45px;
    }
    h3 {
      font-weight: 900;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 3.5px;
      text-shadow:
        0px 64px 64px rgba(28, 65, 84, 0.04),
        0px 64px 48px rgba(28, 65, 84, 0.08),
        0px 24px 24px rgba(28, 65, 84, 0.08);
      text-transform: uppercase;
      position: absolute;
      bottom: 8px;
      margin: 0 auto;
      width: 100%;
    }
    p {
      border-top: 3px solid #e9ff70;
      padding-top: 15px;
      line-height: 22px;
      letter-spacing: 1px;
      text-align: left;
      color: white;
    }
  }
  button {
    position: relative;
    top: -20px;
    margin: 0 !important;
  }
}

.playIt {
  padding-top: 80px;
  text-align: left;
  position: relative;
  .details {
    padding: 40px 0 180px;
    background: $rivall-navy;
    background-image: url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png');
    background-image: -webkit-image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.avif') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.webp') 1x,
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png') 1x
    );
    background-image: image-set(
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.avif') type('image/avif'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.webp') type('image/webp'),
      url('https://rivall-public.s3-us-west-1.amazonaws.com/home-page/xoxo.png') type('image/png')
    );
    color: white;
    position: relative;
  }
  .subtitle {
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    line-height: 36px;
    letter-spacing: 0.25px;
    color: #e9ff70;
    text-transform: uppercase;
  }
  p {
    line-height: 32px;
    text-align: justify;
    margin-top: 15px;
    color: white;
  }
  img {
    float: right;
    position: relative;
    right: -5vw;
    height: 400px;
  }
  .card {
    position: relative;
    padding-bottom: 60px;
    background: white;
    color: $rivall-navy;
    text-align: center;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    .number {
      font-weight: 900;
      font-size: 48px;
      line-height: 58px;
      letter-spacing: 3.5px;
      color: $rivall-navy;
      text-shadow: 0px 2px 0px #ffffff;
      margin: 5px auto;
      background: linear-gradient(transparent 70%, #e9ff70 10%) no-repeat;
      border-radius: 3px;
      width: fit-content;
      width: -moz-fit-content; /* Firefox */
      width: -webkit-fit-content; /* Chrome */
    }
    .blueButton {
      background: $rivall-navy;
      border-radius: 3px;
      position: absolute;
      bottom: 10px;
      left: 10%;
      text-transform: uppercase;
      margin: 0 auto;
      width: 80%;
      padding: 10px 0;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
      margin: 15px 0;
      letter-spacing: -0.5px;
      color: #2d3136;
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 26px;
      color: #818992;
      text-align: center;
    }
  }
  .infoRow {
    position: relative;
    top: -160px;
  }
}

.whereWePlay {
  @extend .homepage;
  position: relative;
  overflow: visible;
  .titleBox {
    position: absolute;
    margin: 0 10%;
    width: 80%;
    z-index: 999;
    top: -50px;
  }
  .mapContainer {
    height: 500px;
    width: 100%;
  }
  .markerDetails {
    margin: 6px 0 0 6px;
    img {
      height: 65px;
      width: 65px;
    }
    h3 {
      font-weight: bold;
      font-size: 16px;
      letter-spacing: 0.25px;
      color: $rivall-navy;
      text-shadow: 0px 2px 0px #ffffff;
      margin: 0 auto;
    }
    .line {
      border-top: 1px solid lightgray;
    }
    .blueButton {
      font-size: 12px;
      padding: 5px 15px;
      display: block;
      margin-top: 9px;
      width: 100%;
    }
  }
}

.voloApp {
  @extend .homepage;
  margin-top: 50px;
  h1 {
    text-align: left;
    margin: 20px 0;
  }
  .volo {
    height: 500px;
    width: auto;
    display: block;
    margin: auto;
  }
  .titleBox {
    padding: 45px 0;
    h2 {
      margin-bottom: 40px;
    }
  }
  .yellowButton {
    margin: 2% 10%;
  }

  &.hidden {
    margin-top: 0;
  }
}

.voloPass {
  @extend .homepage;
  .buttonContainer {
    text-align: center;
    margin: auto;
  }
  .fancyText {
    font-weight: 900;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 3.5px;
    min-width: 45%;
    text-transform: uppercase;
  }
  .benefits {
    float: right;
    text-align: right;
    margin-top: 30px;
  }
  .topFeature {
    padding-top: 40px;
  }
  .descriptionText {
    line-height: 23px;
    letter-spacing: 0.5px;
  }
  .titleBox {
    text-transform: initial;
    margin-top: 50px;
    text-align: center;
    h2 {
      border-bottom: none;
      text-transform: uppercase;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.6px;
      color: #e9ff70;
      font-style: italic;
    }
    p {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
      margin: 20px 0;
    }
  }
}

.upcomingEvents {
  padding-top: 50px;
  .eventCard {
    margin: 10px 0;
    background: #ffffff;
    box-shadow:
      0px 3.05584px 6.11168px rgba(38, 50, 56, 0.08),
      0px 1.52792px 3.05584px rgba(38, 50, 56, 0.16);
    border-radius: 3px;
    width: 100%;
    height: 100%;
    .leftDate {
      padding: 30px 10px;
      float: left;
      width: 40%;
      height: 100%;
      background: $rivall-navy;
      text-align: center;
      text-transform: uppercase;
      border-radius: 3px 0px 0px 3px;
      .day {
        font-weight: bold;
        font-size: 16px;
        line-height: 14px;
        color: #a2cae8;
        margin-bottom: 10px;
      }
      .date {
        font-weight: bold;
        font-size: 36px;
        line-height: 31px;
        color: #ffffff;
      }
    }
    .rightDetails {
      padding: 10px 10px 40px;
      float: right;
      width: 60%;
      height: 100%;
      text-align: left;
      position: relative;
      .event {
        font-weight: bold;
        font-size: 18px;
        letter-spacing: 0.15px;
        color: #2d3436;
        text-transform: uppercase;
        margin-bottom: 9px;
      }
      .subtitle {
        font-weight: bold;
        font-size: 16px;
        color: #2d3436;
        margin-bottom: 9px;
      }
      .description {
        font-size: 16px;
        color: #2d3436;
        margin: 5px 0;
        line-height: inherit;
        text-align: left;
      }
      button {
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        letter-spacing: 0.15px;
        color: $rivall-navy;
        border: 2px solid $rivall-navy;
        border-radius: 3px;
        background: transparent;
        padding: 2px;
        width: 60%;
        position: absolute;
        bottom: 10px;
        left: 20%;
      }
    }
  }
  .blueButton {
    margin-top: 20px;
    a {
      color: white;
    }
  }
}

.policies {
  .descriptionBlock {
    h4 {
      font-weight: bold;
      font-size: 18px !important;
      line-height: 32px;
      color: #2b2b2b;
      margin-top: 15px;
    }
    li {
      margin-left: 40px;
    }
    h3 {
      font-weight: 900;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 3.5px;
      text-transform: uppercase;
    }
  }

  .policies--grid-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(145px, 1fr));
  }
}

.sportPage {
  .hero {
    .jumbotron {
      min-height: 100px;
      h1 {
        margin-bottom: 20px;
      }
    }
  }
  p {
    text-align: left;
    line-height: 30px;
    color: #828282;
  }
  .descriptionCard {
    .pricing {
      color: black !important;
      text-align: center !important;
    }
  }
  .feature {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.15px;
      color: #2b2b2b;
    }
    margin: 20px 0;
  }
}

.whatIsVolo {
  p {
    font-size: 16px;
    line-height: 26px;
    align-items: left;
    color: #333333;
    text-align: left;
  }
}

.leaguePerks {
  margin-top: -50px;
  margin-bottom: 20px;
  h2 {
    font-weight: bold;
    font-size: 22px;
    line-height: 37px;
    letter-spacing: -1px;
    color: #000000;
    text-align: left;
  }
  .feature {
    margin: 30px 0;
  }
}

.sportDetail {
  $card-height: 464px;
  .detailInfo {
    margin-bottom: 30px;
    .sportCarousel {
      margin: 0;
      width: 100%;
      img {
        width: 100%;
        height: auto;
        position: relative;
        top: 0;
      }
      h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        letter-spacing: 0.75px;
        color: #000000;
      }
    }
    :global(.carousel .slide) {
      background-color: transparent;
    }
    :global(.carousel-status) {
      display: none;
    }
    :global(.carousel-slider) {
      height: math.div($card-height, 1.25);
    }
    :global(.control-prev.control-arrow:before) {
      border-right: 8px solid $rivall-navy;
    }
    :global(.control-next.control-arrow:before) {
      border-left: 8px solid $rivall-navy;
    }
    :global(.control-dots .dot) {
      background: $rivall-navy;
    }
    :global(::-webkit-scrollbar) {
      display: none;
    }
    .venueCard {
      overflow: scroll;
      $max-height: 300px;
      $mobile-max-height: 600px;
      padding: 1em 2em;
      border-radius: 15px;
      box-shadow:
        0px 4px 8px rgba(38, 50, 56, 0.08),
        0px 2px 4px rgba(38, 50, 56, 0.16),
        0px -4px 4px rgba(0, 0, 0, 0.03);
      @include media-breakpoint-down(md) {
        max-height: $mobile-max-height;
        display: block;
        .venueImg {
          width: math.div($mobile-max-height, 4);
          height: auto;
        }
        .venueDescription {
          overflow: scroll;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
          padding-left: 1em;
          max-height: math.div($mobile-max-height, 1.75);
        }
      }
      @include media-breakpoint-up(md) {
        height: $max-height;
        display: flex;
        align-items: center;
        .venueImg {
          width: 90%;
          height: auto;
        }
        .venueDescription {
          overflow: scroll;
          padding-left: 1em;
          max-height: $max-height - 30;
        }
      }
      h3 {
        font-weight: bold;
        font-size: 20px;
        color: #000000;
      }
      h4 {
        font-weight: normal;
        font-size: 16px;
        color: #000000;
        margin: 8px 0px;
      }
      .pin {
        position: relative;
        left: -14px;
        height: 40px;
      }
    }
  }
  .faqs {
    .card {
      height: auto;
      margin-bottom: 20px;
      position: relative;
    }
  }
  .card {
    background: #ffffff;
    border-radius: 16px;
    text-align: left;
    padding: 20px 25px;
    height: auto;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
    .front {
      transition: transform 0.5s;
    }

    .back {
      transition: transform 0.5s;
      -moz-transform: rotateY(180deg) scaleX(-1);
      -webkit-transform: rotateY(180deg) scaleX(-1);
      transform: rotateY(180deg) scaleX(-1);
    }
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      letter-spacing: 0.2px;
      color: #2b2b2b;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
      letter-spacing: 0.2px;
      color: #2b2b2b;
    }
  }
}

.whyShouldIPlay {
  .descriptionText {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.8px;
    color: #2b2b2b;
    text-align: left;
  }
}

.sportDetail,
.aboutUs,
.faq,
.policies,
.contactUs,
.rules {
  .hero {
    height: 300px;
    .jumbotron {
      min-height: 100px;
      h1 {
        margin-bottom: 20px;
      }
    }
  }

  .rule-container {
    display: flex;
    height: 80vh;

    .rule-card {
      border-radius: 8px;
      box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
      padding: 1rem;
      font-family: $font-family;
      overflow: auto;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .rule-heading {
        border-bottom: 2px solid $rivall-blue-300;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        margin-bottom: 16px;

        h1 {
          font-size: 1.5rem;
          letter-spacing: -1.5px;
          text-overflow: ellipsis;
          width: 80%;
          text-align: left;
        }

        a {
          padding: 8px;
          background-color: $rivall-blue-300;
          color: $grey-100;
          border: 1px solid $grey-100;

          &:hover {
            color: $grey-300;
          }
        }

        .rule-toggle {
          padding: 4px 8px;
          width: 20%;
          font-size: 12px;
        }

        .rule-body {
          line-height: normal;
          letter-spacing: normal;
        }
      }

      h2 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 16px;
      }

      label {
        text-transform: uppercase;
        font-weight: 800;
        letter-spacing: -1px;
      }

      ul {
        margin-top: 8px;
        list-style-type: none;

        .sport-name {
          display: flex;
          padding: 16px 8px;
          cursor: pointer;
          border-bottom: 1px solid $grey-200;

          &:hover {
            background-color: $grey-100;
          }

          img {
            height: 16px;
            margin-right: 8px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
            transition: transform 0.5s;

            &.clicked {
              transform: rotate(-90deg);
              transition: transform 0.5s;
            }
          }
        }
      }
      .rule-name {
        letter-spacing: -0.1px;
        text-align: left;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 8px 8px 8px 2rem;
        border-bottom: 1px solid $grey-100;

        .indicator {
          font-size: 9px;
          font-weight: 800;
          border-radius: 4px;
          margin-right: 8px;
        }

        &.error {
          color: $brick-red;
        }

        &.selected {
          background-color: $neon-yellow;
        }

        &:hover {
          background-color: $grey-300;
        }
      }
    }
  }
}

.contactUs {
  h1 {
    text-align: center;
    min-width: 50%;
    margin: 0 auto;
  }
  .contactRow {
    margin-top: 25px;
    .card {
      text-align: center;
      background: #ffffff;
      border-radius: 16px;
      padding: 20px 25px;
      height: auto;
      box-shadow:
        0px 4px 8px rgba(38, 50, 56, 0.08),
        0px 2px 4px rgba(38, 50, 56, 0.16),
        0px -4px 4px rgba(0, 0, 0, 0.03);
      img {
        height: 100px;
        width: 100px;
        margin-bottom: 10px;
      }
      a {
        display: flex;
        justify-content: center;
        font-size: 20px;
        color: $rivall-navy;
        margin-bottom: 2px;
        &:hover {
          color: white;
          background-color: $rivall-navy;
          box-shadow: 3px 3px 2px rgba(28, 65, 84, 0.4);
        }
      }
    }
  }
}

.aboutUs {
  .historyContainer {
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 30px;
      color: #2b2b2b;
      margin-bottom: 30px;
    }
  }
  .heroContainer {
    margin-bottom: 50px;
  }
  .fancyLong {
    text-align: center;
    width: 40%;
    margin: 20px 30%;
  }
  .history {
    margin-top: 50px;

    // THANK YOU https://codepen.io/girlgeek/pen/OgqBgj
    // SAVED ME 100000 HOURS OF CSS WORK
    .wrapper {
      margin: 30px auto 50px;
      padding: 10px 15px 10px 75px;
      max-width: 90vw;
    }
    .timeline {
      -webkit-transition: all 0.4s ease;
      transition: all 0.4s ease;
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 100%;
        background: $rivall-navy;
        position: absolute;
        top: 0;
        left: -3.313em;
        border-radius: 40px 40px;
      }
    }
    .timelineCard {
      position: relative;
      background: #ffffff;
      box-shadow:
        0px -4px 4px rgba(0, 0, 0, 0.03),
        0px 2px 4px rgba(38, 50, 56, 0.16),
        0px 4px 8px rgba(38, 50, 56, 0.08);
      border-radius: 10px;
      padding: 15px 20px;
      text-align: center;
      margin: 6px 0;
      &:before {
        content: '';
        width: 45px;
        height: 45px;
        background-color: #a2cae8;
        border: 6px solid $rivall-navy;
        display: -webkit-box;
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        border-radius: 50%;
        position: absolute;
        left: -4.5em;
      }
      &:after {
        content: ' ';
        display: block;
        position: absolute;
        height: 8px;
        width: 22px;
        top: 34px;
        background-color: $rivall-navy;
        right: 100%;
      }
      .fancyText {
        font-weight: 900;
        font-size: 38px;
        line-height: 48px;
        letter-spacing: 3.5px;
        margin: 0 auto;
        &:before {
          content: '';
          display: inline-block;
          width: 24px;
          height: 10px;
          position: absolute;
          right: 100%;
          top: 32px;
          background: $rivall-navy;
        }
      }
      .subtitle {
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        letter-spacing: -0.5px;
        color: #2d3136;
        margin: 10px 0;
        text-transform: uppercase;
      }
      .description {
        font-size: 16px;
        line-height: 26px;
        color: #818992;
      }
    }
    @supports (display: grid) {
      @media (min-width: 768px) {
        .wrapper {
          padding: 10px 15px;
        }
        .timeline {
          display: grid;
          grid-gap: 15px;
          grid-template-areas: '. entry1' 'entry2 .' '. entry3' 'entry4 .' '. entry5' 'entry6 .' '. entry7' 'entry8 .';
          &:before {
            left: calc(50% - 22px);
          }
          .timelineCard {
            text-align: left;
            .fancyText:before {
              width: 100px;
            }
            &:after {
              width: 122px;
            }
            &:nth-of-type(odd) {
              margin-left: 115px;
              &:before {
                left: -142px;
                z-index: 9;
              }
              &:after {
                right: 100%;
              }
            }
            &:nth-of-type(even) {
              margin-right: 100px;
              &:before {
                left: calc(100% + 86px);
                z-index: 9;
              }
              &:after {
                left: 100%;
              }
            }
          }
        }
        // TODO: learn enough CSS to condense this
        .timelineCard:nth-child(1) {
          grid-area: entry1;
        }
        .timelineCard:nth-child(2) {
          grid-area: entry2;
        }
        .timelineCard:nth-child(3) {
          grid-area: entry3;
        }
        .timelineCard:nth-child(4) {
          grid-area: entry4;
        }
        .timelineCard:nth-child(5) {
          grid-area: entry5;
        }
        .timelineCard:nth-child(6) {
          grid-area: entry6;
        }
        .timelineCard:nth-child(7) {
          grid-area: entry7;
        }
        .timelineCard:nth-child(8) {
          grid-area: entry8;
        }
        .timelineCard:nth-of-type(even):not(:nth-of-type(odd)) .fancyText:before {
          left: 100%;
        }
      }
    }
  }
}

.corporate {
  .hero {
    .jumbotron {
      min-height: 150px;
    }
  }
  .overview {
    margin-top: 50px;
    h1,
    h3 {
      margin-bottom: 20px;
    }
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      color: #2b2b2b;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 32px;
      color: #2b2b2b;
      margin-bottom: 10px;
    }
  }
  .titleBox {
    position: relative;
    top: 0;
    text-transform: initial;
    margin-top: 50px;
    text-align: center;
    h2 {
      border-bottom: none;
      text-transform: uppercase;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      letter-spacing: 0.6px;
      color: #e9ff70;
      font-style: italic;
      margin-bottom: 20px;
    }
    p {
      font-style: italic;
      font-weight: normal;
      font-size: 16px;
      line-height: 26px;
      color: #ffffff;
      margin: 20px 0;
    }
    .holidayHeader {
      font-size: 20px;
    }
    .holidayContent {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 32px;
    }
    .testimonialCarousel {
      margin-bottom: 30px;
      .testimonial {
        padding: 5px 20px 40px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
      }
      .testimonialImgContainer {
        height: 175px;
        width: 175px;
        padding: 7px;
        border-radius: 50%;
        background-color: white;
      }
      .testimonialImg {
        height: 100%;
        box-sizing: border-box;
      }
      .quote {
        color: white;
        font-style: italic;
        margin: 10px 10%;
        width: 80%;
        div {
          font-style: normal;
        }
      }
    }
    :global(.carousel .slide) {
      background-color: transparent;
    }
    :global(.carousel-status) {
      display: none;
    }
  }
  .textToRight {
    float: right;
    text-align: right;
    margin-top: 30px;
  }
  .customs {
    margin-top: 100px;
  }
}

.formats {
  $format-border: 1px solid lighten($grey-400, 10%);
  %avatar {
    white-space: pre-wrap;
    text-transform: capitalize;
    padding: 0;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 50%;
    max-height: 106px;
    max-width: 106px;
    background: $rivall-navy;
    color: $grey-100;
    font-size: 24px;
    font-weight: 700;
    font-family: $font-family;
    letter-spacing: -2px;
  }
  %defaultCardStyles {
    background: transparent;
    width: 100%;
    height: 140px;
    border-top: $format-border;
    padding: 10px 0 10px 0;
    display: flex;
    justify-content: space-evenly;
    &:hover {
      background-color: $grey-100;
      cursor: pointer;
    }
    .avatar {
      @extend %avatar;
    }
    .description {
      padding: 3px;

      .descTitle {
        text-transform: uppercase;
        color: $rivall-navy;
        font-weight: 800;
      }
      .descInfo {
        margin-top: 2px;
        font-size: 0.95rem;
      }
    }
  }

  .front,
  .back {
    @extend %defaultCardStyles;
  }

  .front {
    transition: transform 0.5s;
  }

  .back {
    transition: transform 0.5s;
    -moz-transform: rotateY(180deg) scaleX(-1);
    -webkit-transform: rotateY(180deg) scaleX(-1);
    transform: rotateY(180deg) scaleX(-1);
  }

  &:last-child {
    border-bottom: $format-border;
  }

  @include media-breakpoint-down(md) {
    .front,
    .back {
      .avatar {
        margin-top: 0.5em;
        font-size: 18px;
        max-height: 80px;
        max-width: 80px;
        font-weight: 500;
        letter-spacing: -1px;
      }
    }
  }
}

.footer {
  @extend .homepage;
  background: $rivall-navy;
  color: white;
  padding: 30px 0;
  text-align: left;
  h3 {
    text-transform: uppercase;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.9px;
  }
  ul {
    margin: 0 15px;
  }
  li {
    list-style-type: none;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.7px;
    a {
      color: #e0e0e0;
    }
  }
  .social {
    border-bottom: none;
    text-transform: uppercase;
    margin-bottom: 10px;
    padding-bottom: 5px;
    font-weight: 700;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.9px;
    text-align: center;
  }
  .footerRow {
    margin: 10px 20px;
  }
  .logofooterRow {
    margin: 50px 50px 50px 50px;
    font-weight: 800;
  }
  img {
    width: 60px;
    height: auto;
  }
  .voloLogo {
    width: 200px;
  }
  p {
    font-size: 15px;
    line-height: 18px;
    color: #e0e0e0;
    text-align: left;
    margin: 15px 0;
  }
  .cityList {
    li {
      line-height: 25px;
    }
  }

  @include media-breakpoint-down(md) {
    padding-bottom: 65px;
    width: 100%;
  }
}

.rules {
  .sportList {
    img {
      height: 60px;
      width: auto;
      margin-right: 10px;
    }
    h1 {
      display: inline-block;
      position: relative;
      bottom: 20px;
    }
    .card {
      height: auto;
      padding: 15px 25px 10px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.alignCenter {
  display: flex;
  align-items: center;
}

.multiPanels {
  %card-defaults {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 3px;
    text-align: center;
    box-shadow:
      0px 4px 8px rgba(38, 50, 56, 0.08),
      0px 2px 4px rgba(38, 50, 56, 0.16),
      0px -4px 4px rgba(0, 0, 0, 0.03);
    &.active {
      border-top: 6px solid $rivall-navy;
    }
    h4 {
      font-size: 20px;
      font-weight: 500;
      color: #828282;
      text-transform: uppercase;
    }
    &:hover {
      cursor: pointer;
      h4 {
        color: $rivall-navy;
      }
    }
    @include media-breakpoint-down(md) {
      box-shadow: none;
      padding: 10px;
      h4 {
        font-size: 1em;
      }
      &.active {
        padding-top: 5px;
      }
    }
  }
  padding-top: 40px;
  margin-bottom: 40px;
  h1 {
    text-align: center;
    margin: 0px auto 18px;
  }
  .description {
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #2b2b2b;
    margin: 0 15% 30px;
  }
  .tabRow {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    .tab {
      padding: 0;
      flex: 1;
    }
    @include media-breakpoint-down(md) {
      .tab {
        min-width: 50%;
        max-width: 50%;
        padding: 0;
      }
    }
  }
  .card {
    @extend %card-defaults;
    padding: 40px 10px;
    height: 100%;
    &.active {
      padding-top: 35px;
    }
    h4 {
      line-height: 26px;
    }
    img {
      height: 60px;
      width: auto;
      margin-bottom: 15px;
    }
  }
  .programTabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    .programTab {
      @extend %card-defaults;
      padding: 12px 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 4.5rem;
      margin: 4px;

      img {
        height: 2.5rem;
        margin-right: 10px;
      }
    }
    // @include media-breakpoint-down(md) {
    //    .programTab {}
    // }
  }
  .descriptionBlock {
    margin: 40px 0 0;
    min-height: 290px;
    .descriptionCard {
      height: 100%;
      display: flex;
      flex-direction: column;
      h2 {
        font-weight: 500;
        font-size: 28px;
        line-height: 26px;
        letter-spacing: -0.7px;
        color: #828282;
        margin-bottom: 15px;
        text-align: left;
        text-transform: uppercase;
      }
      h3 {
        text-align: left;
        font-size: 16px;
      }
      h4 {
        text-align: left;
        font-size: 18px;
        font-style: italic;
        font-weight: 300;
      }
      .info {
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: #828282;
        text-align: justify;
        margin-top: 12px;
      }
      .yellowButton {
        font-size: 15px;
      }
    }
    .placeholder {
      height: 220px;
    }

    @include media-breakpoint-down(sm) {
      .programImage {
        display: none;
      }
    }
  }
  .policyCard {
    h4 {
      font-size: 16px;
    }
  }
}

// kudos!!! https://codepen.io/cjl750/pen/mXbMyo

.horizontal-timeline {
  $numDots: 5;
  $parentWidthBase: 1;
  $parentWidth: $parentWidthBase * 95vw;
  $parentMaxWidth: 900px;
  $dotWidth: 60px;
  $dotWidthSm: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin-bottom: 64px;
  .competitionDescription {
    display: none;
  }
  @include media-breakpoint-down(md) {
    margin-bottom: 12px;
    .competitionDescription {
      display: flex;
      background-color: lighten($rivall-navy, 20%);
      color: white;
      position: relative;
      top: -5vh;
      padding: 2em;
      border-radius: 2em;
    }
  }
  .input-timeline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: math.div($parentWidth, 1.05);
    height: 100px;
    max-width: $parentMaxWidth;
    position: relative;
    z-index: 10;
    margin-top: 32px;
    @include media-breakpoint-down(lg) {
      width: math.div($parentWidth, 1.5);
      height: 20vh;
    }
    .input {
      width: $dotWidth;
      height: $dotWidth;
      background-color: $rivall-navy;
      position: relative;
      border-radius: 50%;

      &.selected {
        background-color: lighten($rivall-navy, 10%);
      }

      &:hover {
        background-color: lighten($rivall-navy, 10%);
        cursor: pointer;
        .tooltip .tooltip-text {
          display: inherit;
        }
        .tooltip::before {
          color: lighten($rivall-navy, 10%);
        }
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        background-color: $rivall-navy;
        width: $parentWidth;
        height: 3px;
      }

      &::before {
        left: calc(#{math.div(-$parentWidth, $numDots)} + #{$dotWidth * 2});
      }

      &::after {
        right: calc(#{math.div(-$parentWidth, $numDots)} + #{$dotWidth * 2});
      }

      .tooltip {
        &::before {
          position: absolute;
          top: -25%;
          transform: translate(-50%, -100%);
          content: attr(data-level);
          color: $rivall-navy;
          width: math.div(800px, $numDots);
          font-size: 22px;
          font-weight: 800;
          text-transform: uppercase;
          letter-spacing: -0.05em;
        }
        .tooltip-text {
          position: absolute;
          border-radius: 16px;
          transform: translate(-40%, 50%);
          top: 20px;
          padding: 2vw 2.5vw;
          margin-left: -5px;
          background: lighten($rivall-navy, 10%);
          color: #ffffff;
          width: math.div($parentMaxWidth, 3);
          min-height: 128px;
          font-size: 16px;
          display: none;

          &::before {
            content: '\A';
            border-style: solid;
            border-width: 30px;
            border-color: transparent transparent lighten($rivall-navy, 10%) transparent;
            position: absolute;
            top: -40px;
            left: 125px;
            min-height: 50px;
          }
          p {
            color: #ffffff;
          }
        }
      }
    }
    @include media-breakpoint-between(md, lg) {
      width: math.div($parentWidth, 1.5);
      .input {
        .tooltip {
          &::before {
            top: -4rem;
            left: -4rem;
            transform: rotate(-20deg);
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      .input {
        width: math.div($dotWidth, 1.5);
        height: math.div($dotWidth, 1.5);

        &::before,
        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: $rivall-navy;
          width: $parentWidth;
          height: 3px;
        }
        &:hover {
          .tooltip .tooltip-text {
            display: none;
          }
          .tooltip::before {
            color: lighten($rivall-navy, 20%);
          }
        }
        .tooltip {
          &::before {
            position: absolute;
            top: 0;
            font-size: 14px;
            font-weight: 600;
            text-transform: uppercase;
            transform: rotate(-45deg);
            text-align: left;
            left: -7.5vw;
            top: -10vh;
          }
        }
      }
    }
  }
}

.homepageV2 {
  h1 {
    font-size: 44px;
    letter-spacing: 1px;
    line-height: 50px;
    text-transform: none;
    margin-bottom: 5px;

    @include media-breakpoint-down(md) {
      letter-spacing: 0;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
  }

  .primaryText {
    color: $rivall-navy;
    font-weight: 700;
    font-size: 16px;
    margin: 1rem auto;
  }

  .blue {
    background-color: #f7fbff;
  }

  .sticky {
    z-index: 9999;
    display: flex;
    position: fixed;
    top: 54px;
    width: 100%;
    padding: 5px 0;
    height: 35px;
    transition: height 0.3s ease-out-in;
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-up(xl) {
      padding-right: 9rem;
      padding-left: 9rem;
    }

    h1 {
      letter-spacing: -1px;

      &.reduceMargin {
        @include media-breakpoint-up(xl) {
          margin-right: -75px;
          margin-left: -75px;
        }
      }
    }

    .ctaButton {
      margin: 30px 0;
      background: $rivall-navy;
      border: 2.6px solid $rivall-navy;
      a {
        color: $rivall-navy;
      }

      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }

    p,
    p.small {
      text-align: left;
      font-size: 14px;
      line-height: 22px;
    }

    .sectionImage {
      position: absolute;
      height: 432px;
      width: 1166px;
      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .sectionHeading {
      margin-bottom: 12px;
      @include media-breakpoint-up(sm) {
        &.center {
          h2 {
            margin-left: auto;
            margin-right: auto;
          }

          p {
            width: 75%;
            margin-left: auto;
            margin-right: auto;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }

    .sectionCard {
      padding: 40px 20px;
      height: 100%;
      background: #ffffff;
      box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      text-align: left;

      &.thinCard {
        @include media-breakpoint-up(sm) {
          min-height: 303px;
        }
      }
    }

    .divider {
      margin: 20px 0;
      height: 5px;
      width: 35px;
      background-color: #4ce0d2;
    }
  }

  .headerCta {
    margin: 30px 0;
    background: #4ce0d2;
    border: 2.6px solid #4ce0d2;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.15);
    a {
      color: #4ce0d2;
    }
  }
}

.voloPassV2 {
  @extend .homepage;
  @extend .homepageV2;
  margin-top: -40px;

  .vpStickyBar {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    height: 65px;
    border-top: 1px solid $grey-200;
    border-bottom: 1px solid $grey-200;
    background-color: white;
    @include media-breakpoint-up(sm) {
      top: 75px;
      &.sticky {
        position: fixed;
        top: 54px;
        width: 100%;
        padding: 5px 0;
        min-height: 54px;
        transition: min-height 0.3s ease-out-in;
      }
    }
    @include media-breakpoint-down(sm) {
      position: fixed;
      bottom: 0px;
      width: 100%;
      padding: 5px 0;
      min-height: 54px;
      transition: min-height 0.3s ease-out-in;

      &.atBottom {
        bottom: 51px;
      }
    }

    img {
      height: 44px;
      margin-right: 40px;
    }

    p {
      text-align: center;
      width: 200px;
      letter-spacing: 0;
      font-size: 12px;
      margin-right: 40px;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  .vpLogo {
    order: 0;
    max-width: 100%;
    width: 10rem;
    padding: 0.2rem 0 0.2rem 0;
  }
}

.separator {
  margin: 6px;
  border-bottom: 2px solid #0171b9;
}
